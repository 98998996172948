import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Cell, CellAction } from '../../componentes/Cell';
import TableCell from '@material-ui/core/TableCell';
import FormMovimientosCtacte from '../../componentes/Formularios/FormMovimientosCtacte';
import Tooltip from '@mui/material/Tooltip';

import FormImprimirCtaCte from './FormImprimirCtaCte'

import Icons from '../../componentes/Icons';

import ModalDialog from '../../componentes/Modals/ModalDialog';
import Modal from '../../componentes/Modals/Modal';

import PDFCtaCte from '../../componentes/Pdf/pdfCtaCte/PDFCtaCte';

import IconButton from "@material-ui/core/IconButton";
import LayoutTest from '../../Layout/LayoutTest';
import { setDataPdfAction } from '../../Redux/actions/PdfActions/PdfActions';
import { useDispatch, useSelector } from 'react-redux'

//api
import { CtacteApi } from '../../api/CtacteApi';

//hooks
import useCargarTabla from '../../hooks/useCargarTabla';

//utils 
import { decodeToken } from '../../utils/authToken';

const width = '90vw'

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650,
            borderRadius: 5,
            height: 'max-content',
        },
        tableContainer: {
            padding: 10,
            maxHeight: '80vh',
            borderRadius: 5,
            overflowY: 'hidden',
            [theme.breakpoints.down("md")]: {
                maxHeight: '70vh'
            },
            display: 'flex',
            alignItems: 'start',
            overflowY: 'scroll',

        },
        container: {
            height: '100%',
            minHeight: 'max-content',
            width: width,
            [theme.breakpoints.down("md")]: {
                height: '92vh'
            },

        },
        rowHeader: {
            background: '#fff',
            width: "100%",
            height: 'max-content'
        },
        cellHeader: {
            color: '#3e3e3c',
            fontWeight: '700'
        },
        containerHeader: {
            height: '15%',
            [theme.breakpoints.down("md")]: {
                maxHeight: '10%'
            },
            display: 'grid',
            placeItems: 'center',
            width: '100%'
        },

        header: {
            padding: '10px',
            height: '60%',
            width: '100%',
            borderRadius: 5,
        },
        paginationTable: {
            color: '#6E6893',
            height: 35,
        },
        footer: {
            height: 35
        },

        button: {
            display: 'inline-block',

            padding: 0,
            minHeight: 0,
            minWidth: 0,
        }
    }));

const StyledTableRow = withStyles(() => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#efefef"
        }
    }
}))(TableRow);

const TablaCtaCte = ({ data }) => {
    const classes = useStyles();

    const dispatch = useDispatch()

    //modal para agregar movimiento

    const [modalAgregarMov, setModalAgregarMov] = useState(false)
    const [modalImprimirCtaCte, setModalImprimirCtaCte] = useState()
    const [modalPdfCtaCte, setModalPdfCtaCte] = useState()

    const { dataTable, loading, recargarTabla } = useCargarTabla(() => new CtacteApi().getCtacte(data.idcuentapersona))

    const [ctacte, setCtacte] = useState([])
    const [movPrevCtaCte, setMovPrevCtaCte] = useState({})
    const [dataEdit, setDataEdit] = useState({ ...movPrevCtaCte })
    const [totales, setTotales] = useState({})
    const [configForm, setConfigForm] = useState({})
    const [tablaFiltradaPorFecha, setTablaFiltradaPorFecha] = useState([])

    /* if(!(strstr($ctacte->getConcepto(),"RC"))) {

        if((strpos($ctacte->getConcepto(),"*")===false   )) {
                echo $ctacte->getConcepto();
        } else {
                echo '<font color="#32CD32"><b>'.$ctacte->getConcepto().'</b></font>';
        }
} else {
echo "<b>".$ctacte->getConcepto()."<b>";
 */

    console.log("ESTA ES LA TABLA ",ctacte)

    const changeColor = (text) => {
        if(typeof(text)!= 'string') return {}
        if(parseFloat(text) && parseFloat(text)>0) return {color: '#F20B0B'}
        if (!text.includes('RC')) {
            if (!text.includes('*')) {
                return {}
            } else {
                return { fontWeight: 600, color: '#32CD32' }
            }
        } else {
            return { fontWeight: 600 }
        }
    }

    const cargarCtaCte = () => {
        let saldomov = 0
        const { saldoTotal = 0, totalDebe = 0, totalHaber = 0 } = dataTable.length && calcularTotales()
        let ctacte = dataTable.length && dataTable.map(mov => {
            saldomov = saldomov + mov['debe'] - mov['haber']
            return { ...mov, saldo: saldomov.toFixed(2) }
        })

        dataTable.length && setTotales(calcularTotales())
        dataTable.length && setCtacte([...ctacte, { concepto: "<<<<<<<<TOTALES>>>>>>>>", debe: totalDebe, haber: totalHaber, saldo: saldoTotal }])
        dataTable.length && setConfigForm(dataTable[0])
    }

    useEffect(() => {
        cargarCtaCte()
    }, [loading])


    const { pdfData, pdfDataConfig } = useSelector(state => state.PdfState)


    //configForm  es el primer obj que encuentra en el array del listado de ctacte
    //const { ctacte, previous, configForm, totalDebe, totalHaber, saldoTotal } = useSelector((state) => state.CtacteState);

    const cargarPrev = (data) => {
        setDataEdit({
            ...dataEdit,
            ...data
        })
    }


    console.log("Tabla filtrada  ", tablaFiltradaPorFecha)

    const calcularTotales = () => {
        return {
            saldoTotal: dataTable.length && dataTable.reduce((acc, curr) => { return acc + (curr["debe"] - curr["haber"]) }, 0).toFixed(2),
            totalDebe: dataTable.length && dataTable.reduce((acc, curr) => { return acc + curr["debe"] }, 0).toFixed(2),
            totalHaber: dataTable.length && dataTable.reduce((acc, curr) => { return acc + curr["haber"] }, 0).toFixed(2)
        }
    }

    const obtenerFechasLimites = (dataTable) => {
        return dataTable.length ? { desde: dataTable[0].fecmov, hasta: dataTable[dataTable.length - 1].fecmov } : ''
    }

    const columns = [
        { title: "FecMov", width: 'max-content' },
        { title: "Concepto", width: '30%' },
        { title: "Debe", width: '15%', align: 'right' },
        { title: "", width: '5%', align: 'right', name:'pagado'},
        { title: "Haber", width: '15%', align: 'right' },
        { title: "Saldo", width: '15%', align: 'right' },
        { title: " ", width: 25 },
    ]

    const buttonsAdmin = [
        {
            icon: 'agregar',
            action: () => {
                setModalAgregarMov(!modalAgregarMov)
                setDataEdit({})
            },
            label: 'Agregar Movimiento',
        },
        {
            icon: 'imprimir',
            label: 'Imprimir CtaCte',
            action: () => setModalImprimirCtaCte(!modalImprimirCtaCte)
        }
    ]


    const buttonsCaja = [
        {
            icon: 'imprimir',
            label: 'Imprimir CtaCte',
            action: () => setModalImprimirCtaCte(!modalImprimirCtaCte)
        }
    ]

    const actions = [
        {
            icon: "editar",
            action: (data) => {
                cargarPrev(data);
                setModalAgregarMov(!modalAgregarMov)
            },
            tooltip: "Editar Movimiento"
        }
    ]

    const handleChangeEdit = ({ target }) => {
        const { name, value } = target
        setDataEdit({
            ...dataEdit,
            [name]: value
        })
    }

    const filtrarPorFechas = (obj, fecDesde, fecHasta) => {
        let fDesde = new Date(fecDesde.split('/').reverse().join('-')).getTime()
        let fHasta = new Date(fecHasta.split('/').reverse().join('-')).getTime()
        setTablaFiltradaPorFecha(obj.filter((item, i) => {
            if (i == obj.length - 1) return item
            let fItem = new Date(item.fecmov.split('/').reverse().join('-')).getTime()
            if (fItem >= fDesde && fItem <= fHasta) return item
        }))
    }

    return (<LayoutTest
        buttons={decodeToken('idCaja') ? buttonsCaja : buttonsAdmin}
        header={false}
        appBar={false}
        customHeader={
            <>
                <Grid container item xs={12} className={classes.containerHeader}>
                    <Grid item xs={12} className={classes.header}>
                        {typeof configForm == 'object' && Object.entries(configForm).length &&
                            <Grid item xs={12} >
                                <Typography variant="h6">{`${configForm?.codigo} - ${configForm?.nombre} [${configForm?.codcar}-${configForm?.codfac}]-${configForm?.cuenta}`}</Typography>
                            </Grid>}
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    TOTALES
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    {`Debe $${totales.totalDebe}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    {`Haber $${totales.totalHaber}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    {`Saldo $${totales.saldoTotal}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
    >
        <Grid item xs={12} container alignItems="flex-start" className={classes.tableContainer}>
            <Grid item xs={12}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead className={classes.rowHeader}>
                        <TableRow>
                            {
                                columns.length && columns.map((col, i) =>

                                    <TableCell
                                        className={classes.cellHeader}
                                        align={col.align}
                                        variant="head"
                                        width={col.width}
                                        key={i}>
                                        {col.title}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ctacte.length
                            ? ctacte.map((data, index) =>
                                <StyledTableRow key={index}>
                                    {
                                        columns.map((key,i) => {
                                            return key.title === " " ? null :
                                                <Cell
                                                    align={key.align}
                                                    size={'medium'}
                                                    allRowData={data}
                                                    columna={key.title}
                                                    openModal={() => key.openModal && (() => { })}
                                                    boolean={data['pagado'] && key.boolean}
                                                    isArray={key.isArray}
                                                    width={key.width}
                                                    action={key.action} >
                                                    <Typography style={{ ...changeColor( i > 0 && data[key.title.toLowerCase()]), fontSize:12 }}>
                                                        {key.name === 'pagado' && data['idtipomov'] === 1 ? ((!data['pagado'] ) ? <p>&#x274C;</p> : <p>&#x2705;</p>) :  data[key.title.toLowerCase()]}
                                                    </Typography>
                                                </Cell>
                                        })}

                                    { decodeToken('idCaja') ? null                           
                                    : <CellAction align='right'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                            {actions.map((accion, index) =>
                                                <Tooltip title={accion.tooltip ? accion.tooltip : ''} followCursor>
                                                    <IconButton
                                                        className={classes.button}
                                                        key={index}
                                                        onClick={accion.action && (() => accion.action(data))}
                                                    >
                                                        <Icons type={accion.icon}/>
                                                    </IconButton>
                                                </Tooltip>

                                            )
                                            }
                                        </div>
                                    </CellAction>
                                    }
                                </StyledTableRow>)
                            : <StyledTableRow style={{ height: 54.5 }}>
                                <TableCell colSpan={20}>
                                    <Typography variant="h6">No se encontraron movimientos en la ctacte</Typography>
                                </TableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>

        </Grid>
        <ModalDialog
            hiddenButton={true}
            title={Object.entries(dataEdit).length ? "Actualizar Movimiento" : "Agregar Movimiento"}
            active={modalAgregarMov}
            labelButtonSubmit="Si, Transferir caja"
            closeModal={() => setModalAgregarMov(!modalAgregarMov)}
        >
            <FormMovimientosCtacte
                accionCancelar={() => setModalAgregarMov(!modalAgregarMov)}
                data={ctacte[0]}
                width={'100%'}
                dataEdit={dataEdit}
                recargarTabla={() => recargarTabla()}
                funcSuccess={() => setModalAgregarMov(!modalAgregarMov)}
            />
        </ModalDialog>

        <ModalDialog
            hiddenButton={true}
            title="Imprimir Cuenta Corriente "
            active={modalImprimirCtaCte}
            accionAcpetar={() => { }}
            labelButtonSubmit="Imprimir"
            closeModal={() => setModalImprimirCtaCte(!modalImprimirCtaCte)}
        >
            <FormImprimirCtaCte
                fechas={obtenerFechasLimites(dataTable)}
                tablactacte={ctacte}
                cerrarModal={() => setModalImprimirCtaCte(!modalImprimirCtaCte)}
                filtrarTabla={filtrarPorFechas}
                abrirPdf={() => setModalPdfCtaCte(!modalPdfCtaCte)}
            />

        </ModalDialog>


        {/* MODA PDF CTACTE */}
        <Modal
            width='100vw'
            height='100vh'
            fondo='rgba(0, 0, 0, .4)'
            colorRelleno={'none'}
            closeModal={() => {
                setModalPdfCtaCte(!modalPdfCtaCte)
                dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
            }}
            active={modalPdfCtaCte}
        >
            <PDFCtaCte
                pdfData={tablaFiltradaPorFecha}
                pdfDataConfig={pdfDataConfig}
                height={window.screen.height * 0.8}
                width={window.screen.width * 0.90}
            />
        </Modal>
    </LayoutTest>
    );
}

export default TablaCtaCte