
import LayoutTest from '../../Layout/LayoutTest'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//PDF 
import PdfRecibos from '../../componentes/Pdf/pdfRecibo/PdfRecibos'

//Actions
import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'
import { setDataPdfAction } from '../../Redux/actions/PdfActions/PdfActions';

//Utils
import moment from "moment";

//Api
import { CajaApi } from '../../api/CajaApi'

// components
import Table from '../../componentes/CustomTable'
import SearchBar from '../../componentes/SearchBar/SearchBar'
import Filtro from './Filtro'
import Modal from '../../componentes/Modals/Modal'
import ActionMenu from '../../componentes/Menu/ActionMenu'
import ModalDialog from '../../componentes/Modals/ModalDialog'
import { Grid, Typography } from '@material-ui/core';
import FormImprimirMovCaja from './FormImprimirMovCaja';
import FormEditMovCaja from './FormEditMovCaja';
import FormEnviarReciboMail from './FormEnviarReciboMail'

//hooks 
import useEnviarDatos from '../../hooks/useEnviarDatos';
import useSearchTable from '../../hooks/useSearchTable'
import useCargarTabla from '../../hooks/useCargarTabla'

const cajaApi = new CajaApi()

const Caja = () => {
  // se obtiene el tamaño de la pantalla para luego obtener el numero de filas por pagina 
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  const rowsPerPage = matchesXL ? 12 : matchesLG ? 10 : matchesMD ? 8 : matchesSM ? 6 : 5

  const dispatch = useDispatch()
  //===============================================================================================
  //ESTADOS PARA ABRIR MODALES 
  const { detallesData } = useSelector(state => state.GlobalState)
  const { pdfData, pdfDataConfig } = useSelector(state => state.PdfState)

  const [modalDetallesMov, setModalDellesMov] = useState(false)
  const [modalImprimirMov, setModalImprimirMov] = useState(false)
  const [modalImprimirRecibos, setModalImprimirRecibos] = useState(false)
  const [modalEnviarReciboMail, setEnviarReciboMail] = useState(false)
  const [modalAnularRecibo, setModalAnularRecibo] = useState(false)

  const [modalNotaCreditoDebito, setModalNotaCreditoDebito] = useState(false)

  const [selectPage, setSelectPage] = useState()

  const [modalEditarMov, setModalEditarMov] = useState(false)

  const [checkRow, setCheckRow] = useState({ checkRow: '' })
  const [checkAllPage, setChekAllPage] = useState({ checkAll: '' })

  const [listMovNoFacturados, setListMovNoFacturados] = useState([])
  const [listMovNoFacturadosPg, setListMovNoFacturadosPg] = useState([])


  //===============================================================================================

  const seleccionarFila = (value) => {

    if (!(parseInt(value.ndoc) || value.cuit)) {
      return alert("DNI o CUIT invalido")
    }
    setCheckRow({

      ...checkRow,
      [value?.id]: !checkRow[value?.id]
    })
    const existe = !!listMovNoFacturados.find(data => data.id === value.id)
    existe ? setListMovNoFacturados(listMovNoFacturados.filter(data => data.id !== value?.id))
      : setListMovNoFacturados([...listMovNoFacturados, value])
  }

  const [params, setParams] = useState({
    fechaDesde: moment().subtract(1, 'year').format('DD/MM/YYYY'),
    fechaHasta: moment().format('DD/MM/YYYY')
  })

  const handleChangeFilter = ({ target }) => {
    const { name, value } = target

    setParams({
      ...params,
      [name]: value
    })
  }
  //state MODAl notificacion 

  const [movSinFacturar, setMovSinFacturar] = useState(false)
  const [enviarFacturaAfip, setEnviarFacturaAfip] = useState(false)

  //cargar tablas
  const {
    dataTable: movRechazados,
    loading: loadingMovRechazados,
    recargarTabla: recargarTablaMovRechazados } = useCargarTabla(() => cajaApi.getMovCajaSinFacturar())

  const { dataTable, loading, recargarTabla } = useCargarTabla(() => cajaApi.getAllMovimientos(params))

  //  const {dataTable:detallesMov, loading:loadDetallesMov} = useCargarTabla(()=> new CajaApi().verDetallesDelMov(detallesData.id))

  const {
    sendData: buscarDetallesMov,
    data: detallesMov,
    loading: loadDetallesMov } = useEnviarDatos({
      action: () => cajaApi.verDetallesDelMov(detallesData.id),
      snackBar: false
    })

  //const [modalNotaCreditoDebito, setModalNotaCreditoDebito] = useState(false)

  //funcion para enviar notas a Afip
  const {
    sendData: enviarNotasAfip,
    loading: LoadEnviarNotasAfip
  } = useEnviarDatos({
    action: () => cajaApi.enviarNotasAfip(detallesData),
    funcSuccess: () => {
      setModalNotaCreditoDebito(!modalNotaCreditoDebito)
      recargarTabla()
    }
  })


  //funcion para anular Recibos
  const {
    sendData: sendDataAnularRecibo,
    loading: LoadAnularRecibo
  } = useEnviarDatos({
    action: () => cajaApi.anular_desanularRecibo(detallesData),
    funcSuccess: () => {
      setModalAnularRecibo(!modalAnularRecibo)
      recargarTabla()
    }
  })

  // funcion para obtener la pagina actual de los mov rechazados
  const ActualPage = (listData) => {
    return (listData).slice((movRechazados.length < rowsPerPage ? 0 : selectPage) * rowsPerPage,
      (movRechazados.length < rowsPerPage ? 0 : selectPage) * rowsPerPage + rowsPerPage)
  }

  const { sendData: enviarReciboPorMail, loading: loadingEnviarReciboMail } = useEnviarDatos({
    action: cajaApi.enviarPdfRecibo,
    textSucces: 'Recibo Enviado por mail correctamente',
    funcSuccess: () => setEnviarReciboMail(false),
    funcError: () => { }
  })

  // enviar movimiento rechazado
  const { sendData, loading: loadingEnviarMovAfip } = useEnviarDatos({
    action: cajaApi.enviarFacturaAfip,
    //dataSubmit: listMovNoFacturados.length ? listMovNoFacturados :  ,
    textSucces: 'factura Electronica generada',
    recargarTabla: () => {
      recargarTablaMovRechazados()
      setListMovNoFacturados([])
      setListMovNoFacturadosPg([])
      setCheckRow(checkRow)
    },
    funcSuccess: () => {
      if (detallesData.idTipoFactura != 4) {
        enviarReciboPorMail({ ...detallesData, ...pdfData[0] })
      }
      setEnviarFacturaAfip(!enviarFacturaAfip)
    },
    funcError: () => setEnviarFacturaAfip(!enviarFacturaAfip)
  })

  const {
    filterTable,
    searchText,
    setSearchText,
    setFilter } = useSearchTable((filter, searchText) => cajaApi.getMovimientosCajaWithFilter(filter, searchText))


  const actions = [{
    icon: 'null',
    action: () => { },
  }]

  const actionsTablaMovRechazados = [{
    icon: 'enviar',
    action: (data) => {
      dispatch(selectDataAction(data))
      setEnviarFacturaAfip(!enviarFacturaAfip)
    },
    tooltip: "Enviar Recibo"

  }]

  const buttons = [
    {
      icon: 'recibo',
      action: () => setMovSinFacturar(!movSinFacturar),
      label: 'Movimientos no facturados',
      notification: () => movRechazados.length
    }
  ]

  const seleccionarPagina = (value) => {
    setChekAllPage({
      ...checkAllPage,
      [value]: checkAllPage[value] && checkAllPage[value].length ? [] : ActualPage(movRechazados)
    })
  }

  // comparar listas y eliminar similares
  // recive dos listas de objetos, eliminara los obj. de 
  // listA que sean iguales a los de ListB
  // NO SE MODIFICA LA LISTA ORIGINA 

  const compareAndDelete = (listA, listB, id = "id") => {
    let copyListA = [...listA]
    if (!listB.length) return copyListA

    listB.forEach(data => {
      copyListA.forEach((data2, j) => {
        data[id] === data2[id] && copyListA.splice(j, 1)
      })
    })
    return copyListA
  }


  //funcion para resetear filas checkeadas

  /*   const resetCheck = () => {
      reset
    }  */

  console.log("QUE HAY ACA ADENTRO LISTADO POR PAGINA *********** ", checkAllPage[selectPage])

  const buttonsMovNoFacturados = [
    {
      icon: 'recibo',
      // funcion para seleccionar todos los movimientos no facturados
      // SOLAMENTE SE SELECCIONAN LOS MOVIMIENTOS QUE POSEAN NDOC O CUIT
      action: () => {
        let objCheck = { ...checkRow }
        let listCheck = [...listMovNoFacturados]
        let listCheckPage = []
        ActualPage(movRechazados).forEach(item => {
          if (!(parseInt(item.ndoc) || item.cuit)) return
          objCheck = { ...objCheck, [item?.id]: checkAllPage[selectPage] && checkAllPage[selectPage].length ? false : true }
          listCheck = checkAllPage[selectPage] && checkAllPage[selectPage].length ? compareAndDelete(listMovNoFacturados, checkAllPage[selectPage], 'id') : [...listCheck, item]

        })
        seleccionarPagina(selectPage)
        setListMovNoFacturados(listCheck)
        setListMovNoFacturadosPg(listCheckPage)
        setCheckRow(objCheck)
      },
      label: `${checkAllPage[selectPage] && checkAllPage[selectPage].length ? 'Quitar' : 'Seleccionar'} Mov. de la pag ${selectPage + 1}`,
      display: 'none'
    },

    {
      icon: 'enviar',
      label: 'Enviar Recibos',
      action: () => setEnviarFacturaAfip(!enviarFacturaAfip),
      display: !listMovNoFacturados.length ? 'none' : ''
    }

  ]

  console.log("ESTO SON LAS PAGINAS SELECCIONADAS ", checkAllPage)

  const optionsMenuAction = [
    {
      title: "Ver Detalles ",
      icon: "view",
      action: () => {
        setModalDellesMov(!modalDetallesMov)
        buscarDetallesMov()
      }
    },
    {
      title: "Imprimir Recibo",
      action: () => setModalImprimirMov(!modalImprimirMov),
      icon: "imprimir"
    },

    {
      title: "Editar Mov.",
      action: () => {
        setModalEditarMov(!modalEditarMov)
        buscarDetallesMov()
      },
      icon: "editar"
    },
    {
      title: !detallesData?.anulado ? 'Anular Recibo' : 'Desanular Recibo' ,
      action: () => setModalAnularRecibo(!modalAnularRecibo),
      icon: "recibo"
    },

    {
      title: "Enviar Recibo",
      action: () => setEnviarReciboMail(!modalEnviarReciboMail),
      //saveAs(blob, `PDF${number}.pdf`)
      icon: 'email'
    },
    {
      title: "Nota de Credito",
      action: () => setModalNotaCreditoDebito(!modalNotaCreditoDebito),
      icon: "recibo"
    },
  ]

  const optionsMenuActionCaja = [
    {
      title: "Ver Detalles ",
      icon: "view",
      action: () => {
        setModalDellesMov(!modalDetallesMov)
        buscarDetallesMov()
      }
    },
    {
      title: "Imprimir Recibo",
      action: () => setModalImprimirMov(!modalImprimirMov),
      icon: "imprimir"
    },
    {
      title: !detallesData?.anulado ? 'Anular Recibo' : 'Desanular Recibo' ,
      action: () => setModalAnularRecibo(!modalAnularRecibo),
      icon: "recibo"
    },

    {
      title: "Enviar Recibo",
      action: () => setEnviarReciboMail(!modalEnviarReciboMail),
      //saveAs(blob, `PDF${number}.pdf`)
      icon: 'email'
    },
    {
      title: "Nota de Credito",
      action: () => setModalNotaCreditoDebito(!modalNotaCreditoDebito),
      icon: "recibo"
    },
  ]

  const columnsDetallesMov = [
    { title: 'Descripcion', name: "descripcion" },
    { title: "Valor", name: "valor" }
  ]

  const columns = [
    { title: "fechaMov" },
    { title: "Descripcion" },
    { title: "Monto", width: 50 },
    { title: "Nro Recibo Local", name: 'recibo' },
    { title: 'Nro Recibo AFIP', name: 'nroAfip' },
    { title: "Cuenta", name: 'descripcionCuenta' },
    { title: "Nombre de persona", name: 'nombrePersona' },
    { title: "Transferido", name: 'transferido', type: 'boolean' },
    { title: "CAE", name: 'CAE', type: 'boolean' },
    { title: "Modos de pago", name: 'modoPago' },
    { title: "Nota de Credito", name: 'nroAfipCredito', type: 'boolean' },
    { title: "Anulado", name: "anulado", type: "boolean" },
  ]

  const filtrosSearchBar = [
    {
      key: "DESCRIPCION",
      label: "Descripcion"
    },
    {
      label: 'Nombre Persona',
      key: 'nombre'
    },
    {
      key: "RECIBO",
      label: "Nro Recibo"
    },
    {
      key: "fechaMov",
      label: "Fecha Mov."
    },
  ]

  const columnsMovRechazados = [
    //{ title: "", width: 30, type: 'check' },
    { title: 'Persona', name: 'nombre' },
    { title: 'Nro Doc.', name: 'ndoc' },
    { title: "Recibo" },
    { title: 'CUIT', name: 'cuit' },
    { title: "fechaMov" },
    { title: "Descripcion" },
    { title: 'CAE', type: 'boolean', width: '10%' },
    { title: "Monto", width: 50 },
  ]

  
  return <LayoutTest
    filter={
      <Filtro
        recargarTabla={recargarTabla}
        params={params}
        handleChangeFilter={handleChangeFilter}
      />
    }
    searchBar={
      <SearchBar
        filtros={filtrosSearchBar}
        searchText={searchText}
        setSearchText={setSearchText}
        setFilter={setFilter}
      />
    }
    buttons={buttons}
  >

    <ModalDialog
      title="Generar Factura Afip"
      active={enviarFacturaAfip}
      loading={loadingEnviarMovAfip}
      hiddenButton={loadingEnviarMovAfip}
      accionAcpetar={() => { /* sendData(listMovNoFacturados) */ sendData(detallesData) }}
      labelButtonSubmit={`${listMovNoFacturados.length ? "Si, Enviar" : "Si, Enviar Factura"}`}
      closeModal={() => setEnviarFacturaAfip(!enviarFacturaAfip)}
    >
      {
        listMovNoFacturados.length
          ? <Typography variant="h6">{`¿Esta Seguro que desea enviar ${listMovNoFacturados.length} recibos a Afip ?`}</Typography>
          : <Typography variant="h6">{`¿Esta Seguro que desea enviar la factura a Afip ?`}</Typography>

      }

    </ModalDialog>

    {/* MODAL ANULAR RECIBO */}
    <ModalDialog
      closeModal={() => setModalAnularRecibo(!modalAnularRecibo)}
      active={modalAnularRecibo}
      accionAcpetar={() => { sendDataAnularRecibo(detallesData) }}
      loading={LoadAnularRecibo}
      title={!detallesData?.anulado ? '¿ Esta seguro que desea anular el siguiente recibo ?' : '¿ Esta seguro que desea desanular el siguiente recibo ?' }
    >

      {
        <Typography variant="h6">
          {`  
          Nombre: ${detallesData.nombrePersona} 
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Cuenta: ${detallesData.descripcionCuenta}
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Monto:  ${detallesData.monto}
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Nro Recibo: ${detallesData.recibo}
          `}
        </Typography>
      }
    </ModalDialog>

    {/* MODAL IMPRIMIR MOVIMIENTO DE CAJA  */}
    <ModalDialog
      closeModal={() => setModalImprimirMov(!modalImprimirMov)}
      active={modalImprimirMov}
      hiddenButton={true}
      title="Imprimir Recibos"
    >
      <FormImprimirMovCaja
        openSuccesModal={() => {
          setModalImprimirMov(!modalImprimirMov);
          setModalImprimirRecibos(!modalImprimirRecibos) /* se cierra el modal anterior y se abre el pdf una vez esten cargados los datos */
        }}
        datosMov={detallesData}
        cerrarModal={() => setModalImprimirMov(!modalImprimirMov)}
      />
    </ModalDialog>

        {console.log("DETALLES DATA  ",detallesData)}

    {/* MODAL PARA ENVIAR RECIBOS  */}
    {/* 
  const [modalEnviarReciboMail, setEnviarReciboMail] = useState(false)
  
*/}
    <ModalDialog
      closeModal={() => setEnviarReciboMail(!modalEnviarReciboMail)}
      active={modalEnviarReciboMail}
      loading={false}
      hiddenButton={true}
    >
      <FormEnviarReciboMail
        onSubmit={(datos) => {
          enviarReciboPorMail(datos)

        }}
        cerrarModal={() => setEnviarReciboMail(!modalEnviarReciboMail)}
        datosMov={{ ...detallesData, ...pdfData[0] }}
        loading={loadingEnviarReciboMail}
      />
    </ModalDialog>

    {/* MODAL PARA NOTAS DE CREDITOS Y DEBITO SEGUN CORRESPONDA */}

    <ModalDialog
      closeModal={() => setModalNotaCreditoDebito(!modalNotaCreditoDebito)}
      loading={LoadEnviarNotasAfip}
      active={modalNotaCreditoDebito}
      accionAcpetar={() => { /* sendData(listMovNoFacturados) */ enviarNotasAfip(detallesData)

      }}
      title={`Esta seguro que desea hacer una nota de CREDITO para el siguiente recibo:`}
    >
      {console.log(detallesData)}
      {
        <Typography variant="h6">
          {`  
          Nombre: ${detallesData.nombrePersona} 
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Cuenta: ${detallesData.descripcionCuenta}
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Monto:  ${detallesData.monto}
          `}
        </Typography>
      }
      {
        <Typography variant="h6">
          {`  
          Nro Recibo: ${detallesData.recibo}
          `}
        </Typography>
      }


    </ModalDialog>

    {/* MODAL PDF */}

    <Modal
      width='100vw'
      height='100vh'
      fondo='rgba(0, 0, 0, .4)'
      colorRelleno={'none'}
      closeModal={() => {
        setModalImprimirRecibos(!modalImprimirRecibos)
        dispatch(setDataPdfAction({ pdfData: [], pdfDataConfig: {} }))
      }}
      active={modalImprimirRecibos}
    >
      <PdfRecibos
        pdfData={pdfData}
        pdfDataConfig={pdfDataConfig}
        height={window.screen.height * 0.8}
        width={window.screen.width * 0.90}
      />
    </Modal>


    {/* MODAL DETALLES DEL MOVIMIENTO*/}


    <Modal
      width="45vw"
      active={modalDetallesMov}
      closeModal={() => setModalDellesMov(!modalDetallesMov)}
    >
      <LayoutTest
        header={false}
        appBar={false}
        title="Detalles del movimiento"
      >
        <Table
          modalTable={true}
          tableData={detallesMov && detallesMov.length ? detallesMov : []}
          actions={actions}
          columns={columnsDetallesMov}
        />

      </LayoutTest>

    </Modal>

    {/* MODAL EDITAR MOV. */}

    <Modal
      width='45vw'
      active={modalEditarMov}
      closeModal={() => setModalEditarMov(!modalEditarMov)}

    >
      <FormEditMovCaja
        detallesMov={() => cajaApi.verDetallesDelMov(detallesData.id)}
        recargarTabla={() => {
          recargarTabla()
          recargarTablaMovRechazados()

        }} />
    </Modal>

    {/* MODAL MOVIMIENTOS RECHAZADOS */}
    {/* ================================================================================== */}
    <Modal
      width={'85vw'}
      active={movSinFacturar}
      closeModal={() => { setMovSinFacturar(!movSinFacturar) }}
    >
      <LayoutTest
        buttons={[]}
        header={false}
        appBar={false}
        customHeader={
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h4" >
                Movimientos NO facturados
              </Typography>
            </Grid>
            {listMovNoFacturados.length ? <Grid item>
              <Typography variant="h6" >
                Movimientos seleccionados: {listMovNoFacturados.length}
              </Typography>
            </Grid> : null}

          </Grid>
        }
      >
        <Table
          setSelectPage={setSelectPage}
          loading={loadingMovRechazados}
          modalTable={true}
          tableData={movRechazados}
          actions={actionsTablaMovRechazados}
          columns={columnsMovRechazados}
          rowChek={checkRow}
          selectRow={seleccionarFila}
        />

      </LayoutTest>
    </Modal>
    {/* ================================================================================== */}
    <Table
      filterTable={filterTable.length}
      RowMenu={(data) => {

        return <ActionMenu onClick={() => {
          dispatch(selectDataAction(data))
        }}
          data={data}
          options={optionsMenuAction}
          condicionVerOpcion={'CAE'}
        />
      }}
      tableData={filterTable.length ? filterTable : dataTable}
      columns={columns}
      modalTable={false}
      actions={[]}
    />
  </LayoutTest>
}

export default Caja