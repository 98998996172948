import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Divider, Grid, InputAdornment, Typography, } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";

import * as useFormCustom from '../../hooks/useForm'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";


//Api
import { CajaApi } from '../../api/CajaApi';

// Import Componentes
import Controls from '../Forms/Controls'
import FormContainer from '../Forms/FormContainer';
import Modal from '../Modals/Modal'
import PdfRecibos from '../Pdf/pdfRecibo/PdfRecibos'

// Custom hooks
//import { useForm } from '../../hooks/useForm'
import useEnviarDatos from '../../hooks/useEnviarDatos';

//utils
import { decodeToken } from '../../utils/authToken';


//Actions
import { getAllModosPagos, setDataReciboGeneral, getTiposComprobantes } from '../../Redux/actions/caja/CajasActionCreator';

const ReciboGeneral = ({ cerrarModal }) => {


    const initialFValues = {
        codalu: '0',
        cuenta: '',
        fecha: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        modopago: 1,
        fechavto: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        cantcuotas: '1',
        nombre: '',
        mes: '',
        anio: '',
        beneficio: '',
        valorBeneficio: 0,
        pagoefectivo: 0,
        fechapago: new Date().toISOString().slice(0, 10).split("-").reverse().join("/"),
        arancelCuenta: '',
        debito: 0,
        recibo: '',
        concepto: '',
        interes: 0,
        otropago: null,
        montoTotal: 0,
        cantdias: 0,
        idcuentapersona: '',
        tipoDocumento: 96, //96 DNI  //80 CUIT  //86 CUIL
        ndocCuit: '',
        ndoc: '',
        cuit: '',
        tipoComprobante: 1, //(15)RECIBO C,  (11)FACTURA C 
        intereses: 0,
        biblioteca: 0,
        bonificacion: 0,
        arancel: 0,
        moratoria: 0
    }

    const schema = yup
        .object({
            ndocCuit: yup.string().required('No se encontro un N° de Doc. actualice los datos de la persona y vuelva a intetar nuevamente'),
            montoTotal: yup.number().required('No se puede generar un recibo sin monto')
        })
        .required();

    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useFormCustom.useForm(initialFValues);

    const { dataCollapseTable } = useSelector(state => state.GlobalState)

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: { ...values, ...dataCollapseTable },
        resolver: yupResolver(schema)
    });

    const cargarDatos = () => {
        setDataReciboGeneral(dataCollapseTable.idcuentapersona)
            .then(resp => {
                setValues({ ...initialFValues, ...dataCollapseTable, ...resp })
                setValue("recibo", resp.recibo)
                setValue("concepto", resp.concepto)
                setValue("mes", resp.mes)
                setValue("anio", resp.anio)
                setValue("arancel", resp.arancel ? resp.arancel : 0)
                setValue("arancelCuenta", resp.arancel ? resp.arancel : 0)
                setValue("ndoc", resp.ndoc)
                setValue("beneficio", resp.beneficio ? resp.beneficio : "No posee")
                setValue('ndocCuit', resp.ndoc != '0' ? resp.ndoc : parseInt(resp.cuit) ? resp.cuit : '')
                setValue("montoTotal", resp.arancel ? resp.arancel : 0 + (resp.beneficio ? (parseInt((resp.beneficio).replaceAll('%', '')) / 100) : 0) * (resp.arancel ? (resp.arancel * -1) : 0) + (resp.biblioteca ? resp.biblioteca : 0));
                setValue('bonificacion', resp.beneficio ? (parseInt((resp.beneficio).replaceAll('%', '')) / 100) * (resp.arancel * -1) : 0)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        cargarDatos()
    }, [])

    //modal pdf
    const [modalReciboPDF, setModalReciboPDF] = useState(false)
    const [interesPorDia, setinteresPorDia] = useState(0.1)
    const [difDias, setDifDias] = useState(0)

    const { sendData, loading, data } = useEnviarDatos({
        action: new CajaApi().grabarReciboEnCaja,
        textSucces: 'Mov. de caja registrado',
        textError: 'Ocurrio un Error',
        funcSuccess: () => {
            setModalReciboPDF(!modalReciboPDF)
            cargarDatos()
        }
    })

    const onSubmit = (data) => {

        return sendData({
            ...values,
            ...data,
            modopago: values.modopago,
            tipoDocumento: values.tipoDocumento,
            tipoComprobante: values.tipoComprobante
        })
    }
    //96 DNI  //80 CUIT  //86 CUIL //91 CI Extranjera
    const getTipoDocumentos = async () => {
        return await [
            {
                descripcion: 'DNI',
                codigo: 96
            },
            {
                descripcion: 'CUIT',
                codigo: 80
            },
            {
                descripcion: 'CUIL',
                codigo: 86
            },
            {
                descripcion: 'CI Extranjera',
                codigo: 91
            }
        ]
    }

    // calcular diferencias entre la fecha de vencimiento y la fecha actual 
    // y ademas se calcula los intereses 

    const calMontoTotal = (bon = null) => {
        let montoTotal = (
            parseFloat(getValues("arancel") == '' ? '0' : getValues("arancel")) +
            parseFloat(getValues("biblioteca") == '' ? '0' : getValues("biblioteca")) +
            parseInt(parseFloat(bon ? bon : getValues("bonificacion") == '' ? '0' : getValues("bonificacion"))) +
            parseFloat(getValues("intereses") == '' ? '0' : getValues("intereses")))
        return setValue("montoTotal", montoTotal)
    }

    const handleChangeFVto = (e) => {
        let fechaActual
        let fechaVto

        if (e.target.name == "fechapago") {
            fechaActual = moment(e.target.value, "DD/MM/YYYY")
            fechaVto = moment(values.fechavto, "DD/MM/YYYY")
        }
        if (e.target.name == "fechavto") {
            fechaActual = moment(values.fechapago, "DD/MM/YYYY")
            fechaVto = moment(e.target.value, "DD/MM/YYYY")
        }
        const difDias = fechaActual.diff(fechaVto, 'days')
        setDifDias(difDias)

        let int = parseFloat((difDias * parseFloat(interesPorDia) * (parseFloat(getValues('arancel')) + parseFloat(getValues('biblioteca'))) / 100).toFixed(2))
        setValue('intereses', int)
        setValue('montoTotal', parseFloat(getValues('arancel')) + parseFloat(getValues('biblioteca')) + int + (getValues('bonificacion') && parseFloat(getValues('bonificacion'))))
    }

    const calcBonificacionTest = (event) => {

        const { value } = event.target
        let bon = 0
        if (value.includes('%') && (typeof value == 'string' && value.length > 1 ? true : false)) {
            bon = parseFloat(-1 * (
                parseFloat(getValues("biblioteca")) +
                parseFloat(getValues("arancel"))) * (value.replaceAll('%', '') / 100))
            setValue("bonificacion", parseFloat((getValues("biblioteca")) + parseFloat(getValues("arancel"))) * (value.replaceAll('%', '') / 100))
            setValue("bonificacion", getValues('bonificacion') * (-1))
            calMontoTotal(bon)
            return
        }
        setValue("bonificacion", typeof value == 'string' ? value : parseFloat(value))
        calMontoTotal()
    }

    console.log("VALORES DEL FORMULARIO ", values)

    return (
        <>
            <Modal
                width='100vw'
                height='100vh'
                fondo='rgba(0, 0, 0, 0.2)'
                colorRelleno={'none'}
                closeModal={() => {
                    setModalReciboPDF(!modalReciboPDF)
                }}
                active={data.length && modalReciboPDF}
            >
                <PdfRecibos
                    pdfData={data}
                    height={window.screen.height * 0.8}
                    width={window.screen.width * 0.90}
                />
            </Modal>
            <FormContainer
                onSubmit={handleSubmit(onSubmit)}
                width="70vw"
                LabelButtonSubmit="Generar Recibo"
                resetForm={resetForm}
                title="Recibo General">
                <Grid style={{ padding: '10px', height: '100% !important' }} container spacing={3}>
                    {/*============================================== fila ============================================== */}


                    <Grid item xs={12}>
                        <Typography variant='h6' style={{ color: 'gray', fontWeight: 400 }}>Datos de la persona</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="nombre"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Nombre"
                                    name="nombre"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Controller
                            name={'ndocCuit'}
                            control={control}
                            required
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="N° Doc./CUIT"
                                    name={'ndocCuit'}
                                    value={/* values.ndoc != '0' ? values.ndoc : parseInt(values.cuit) ? values.cuit :  */value}
                                    onChange={() => { }}
                                />
                            )}
                        />

                        {/*                               <Controls.Input
                                 label="N° Doc./CUIT"
                                 name="ndocCuit"
                                 value={parseInt(values.ndoc) ? values.ndoc : parseInt(values.cuit) ? values.cuit : values.ndocCuit}
                                 onChange={handleChangeForm}
                                 required
                             />
  */ }
                        {errors.ndocCuit && <Grid item xs={12}>{<Typography variant="subtitle2" style={{ color: 'red' }}>{errors.ndocCuit?.message}</Typography>}</Grid>}
                    </Grid>

                    <Grid item xs={3}>
                        <Controls.AutocompleteSelect
                            promSelectList={getTipoDocumentos()}
                            name="tipoDocumento"
                            label="Tipo de Documento"
                            filtro='descripcion'
                            valorSalida="codigo"
                            valueautocomplete={values.ndoc != '0' ? 96 : parseInt(values.cuit) ? 80 : values.tipoDocumento}
                            onChange={handleChangeForm}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="cuenta"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Cuenta"
                                    name="cuenta"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{ color: 'gray', fontWeight: 400 }}>Datos del Recibo</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="fecha"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.DateField
                                    title="Fecha"
                                    name="fecha"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.AutocompleteSelect
                            promSelectList={getAllModosPagos()}
                            name="modopago"
                            label="Forma de pago"
                            filtro='descripcion'
                            valorSalida="id"
                            valueautocomplete={values.modopago}
                            onChange={handleChangeForm}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="pagoefectivo"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Pago Efectivo"
                                    name="pagoefectivo"
                                    value={value}
                                    onChange={onChange}
                                />)}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Controller
                            name="mes"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Mes"
                                    name="mes"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={2}>
                        <Controller
                            name="anio"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Anio"
                                    name="anio"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Controller
                            name="recibo"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Recibo"
                                    name="recibo"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            name="concepto"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Concepto"
                                    name="concepto"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controls.DateField
                            title="Fecha de Ven."
                            name="fechavto"
                            value={values.fechavto}
                            onChange={(e) => {
                                handleChangeFVto(e)
                                handleChangeForm(e)
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.DateField
                            title="Fecha de Pago"
                            name="fechapago"
                            value={values.fechapago}
                            onChange={(e) => {
                                handleChangeForm(e)
                                handleChangeFVto(e)
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Controller
                            name="arancelCuenta"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Arancel"
                                    name="arancelCuenta"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        {console.log("beneficio ", values.beneficio)}
                        <Controller
                            name="beneficio"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Beneficio"
                                    name="beneficio"
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </Grid>
                    {decodeToken('idCaja') ? null
                        : <Grid item xs={3}>
                            <Controller
                                name="debito"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Controls.Input
                                        label="Debito"
                                        name="cantdias"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            setValue('arancel', e.target.value)
                                            calMontoTotal()
                                        }}
                                        inputProps={{
                                            min: "0"
                                        }}
                                    />
                                )}
                            />
                        </Grid>}
                    <Grid item xs={decodeToken('idCaja') ? 6 : 3}>
                        <Controls.AutocompleteSelect
                            promSelectList={getTiposComprobantes([1, 4])}
                            name="tipoComprobante"
                            label="Tipo de Comprobante"
                            filtro='descripcion'
                            valorSalida="id"
                            valueautocomplete={values.tipoComprobante}
                            onChange={handleChangeForm}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            name="cantdias"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    label="Cant dias"
                                    name="cantdias"
                                    value={difDias}
                                    onChange={handleChangeForm}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controls.Input
                            label="Int. por Dia"
                            name="interesDia"
                            value={interesPorDia}
                            onChange={(e) => setinteresPorDia(e.target.value ? e.target.value : 0)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            label="Intereses"
                            name="interes"
                            value={difDias * parseFloat(interesPorDia)}
                            onChange={handleChangeForm}
                        />
                    </Grid>
                </Grid>

                {/* detalles del recibo */}

                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" light={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            Detalle de Factura / Recibo
                        </Typography>
                    </Grid>
                    {/* =========================================================== ITEM DESCRIPCIONES =========================================================== */}
                    <Grid item container spacing={1} xs={12}>

                        {/* ARANCEL */}
                        <Grid spacing={1} xs={6} alignItems="center" container item>
                            <Grid item xs={2}>
                                <Typography variant={"subtitle2"}>
                                    Arancel
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Divider orientation="horizontal" light={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    rules={{ required: true }}
                                    name="arancel"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Controls.Input
                                            name={"arancel"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                calMontoTotal()
                                            }}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* BONIFICACION */}
                        <Grid spacing={1} xs={6} alignItems="center" container item>
                            <Grid item xs={2}>
                                <Typography variant={"subtitle2"}>
                                    Bonificacion
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Divider orientation="horizontal" light={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="bonificacion"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Controls.Input
                                            name={"bonificacion"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                calcBonificacionTest(e)
                                            }}
                                            //onBlur={calcBonificacion}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                        </Grid>
                        {/* INTERESES */}
                        <Grid spacing={1} xs={6} alignItems="center" container item>
                            <Grid item xs={2}>
                                <Typography variant={"subtitle2"}>
                                    Intereses
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Divider orientation="horizontal" light={true} />
                            </Grid>
                            <Grid item xs={4}>

                                <Controller
                                    name="intereses"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Controls.Input
                                            name={"intereses"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                calMontoTotal()
                                            }}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* BIBLIOTECA */}
                        <Grid spacing={1} xs={6} alignItems="center" container item>
                            <Grid item xs={2}>
                                <Typography variant={"subtitle2"}>
                                    Biblioteca
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Divider orientation="horizontal" light={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="biblioteca"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Controls.Input
                                            name={"biblioteca"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                calMontoTotal()
                                            }}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {/* MORATORIA */}
                        <Grid spacing={1} xs={6} alignItems="center" container item>
                            <Grid item xs={2}>
                                <Typography variant={"subtitle2"}>
                                    Moratoria
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Divider orientation="horizontal" light={true} />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="moratoria"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Controls.Input
                                            name={"moratoria"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                calMontoTotal()
                                            }}
                                            type="number"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    {/* =========================================================== agregar nueva descripcion =========================================================== */}
                    <Grid container item spacing={1} xs={12}>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" light={true} />
                    </Grid>
                </Grid>

                {/* =========================================================== TOTAL =========================================================== */}
                <Grid container item justifyContent="space-between" alignItems="center">
                    <Grid item xs={4} >
                        <Typography>Total</Typography>
                    </Grid>
                    <Grid item style={{ textAlign: 'end' }} xs={4}>
                        <Controller
                            required
                            name="montoTotal"
                            disabled={true}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Controls.Input
                                    name={"montoTotal"}
                                    value={value}
                                    onChange={onChange}
                                    type="number"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">$</InputAdornment>
                                        ),
                                        style: { textAlign: "right" }
                                    }
                                    }
                                />
                            )}
                        />
                        {errors.montoTotal && <Grid item xs={12}>{<Typography variant="subtitle2" style={{ color: 'red' }}>{errors.montoTotal?.message}</Typography>}</Grid>}
                    </Grid>
                </Grid>

            </FormContainer >
        </>
    )
}
export default ReciboGeneral