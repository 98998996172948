import { Divider, Grid, Typography } from '@material-ui/core';
// Import Componentes
import Controls from '../../componentes/Forms/Controls'
import { useEffect, useState } from 'react';

// Custom hooks
import { useSelector } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import useSubmit from '../../hooks/useSubmit';
import FormContainer from '../../componentes/Forms/FormContainer';

//Actions
import { getAllBeneficios } from '../../Redux/actions/beneficios.Action'

import { getCuentasListSelect } from '../../Redux/actions/cuentas/CuentasActionCreator';

//api
import { PersonaApi } from '../../api/PersonaApi';

//utils 
import { decodeToken } from '../../utils/authToken';

const initialFValues = {
    idCuenta: '',
    codcar: '',
    libredeuda: '',
    idbeneficio: '',
    libredeudarendirparcial: '',
    cantcuotas: '',
    cbu: '',
    idsede: '',
    idalumno: '',
    findesde: '',
    finhasta: '',
    libredeudacursar: '',
    libredeudarendir: '',
    baja: false,
    activo: false,
    recursante: false,
    acreditabanco: false,
    fin: false,
    autogestionactivo: false,
}

const FormEditCollapseTable = () => {
    const { dataCollapseTable } = useSelector(state => state.GlobalState)
    const [isUserCaja, setIsUserCaja] = useState(false);

    const {
        values,
        setValues,
        handleChangeForm,
        resetForm
    } = useForm({ ...initialFValues, ...dataCollapseTable });

    const { formSubmit } = useSubmit(
        new PersonaApi().actualizarLibreDeuda,
        values,
        () => { },
        'Libre Deuda Actualizado'
    )

    useEffect(() => {
        // Verificamos si el usuario es de tipo caja
        const esCaja = decodeToken('idCaja');
        setIsUserCaja(esCaja);
    }, []);

    const handleSubmit = (e) => {

        formSubmit(e);
    }

    return (
        <FormContainer
            width="45vw"
            resetForm={resetForm}
            LabelButtonSubmit="Guardar"
            title="Edicion de Libre Deuda"
            onSubmit={handleSubmit}>
            <Grid xs={12} item>
                <Controls.Input
                    label="ID Alumno"
                    name="idalumno"
                    value={values.idalumno}
                    onChange={handleChangeForm}
                    disabled={isUserCaja}

                />
            </Grid>
            <Grid xs={12} item>
                <Controls.AutocompleteSelect
                    label="Cuenta"
                    name="idCuenta"
                    valueautocomplete={values.idCuenta}
                    filtro={"descripcion"}
                    promSelectList={getCuentasListSelect()}
                    valorSalida="id"
                    onChange={handleChangeForm}
                    disabled={isUserCaja}

                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Input
                    label="Cantidad de cuotas"
                    name="cantcuotas"
                    value={values.cantcuotas}
                    onChange={handleChangeForm}
                    disabled={isUserCaja}

                />
            </Grid>

            {/* ========================================================================================== */}

            {/* autocomplete idbeneficio */}

            <Grid xs={12} item>
                <Controls.AutocompleteSelect
                    label="Beneficio"
                    name="idbeneficio"
                    valueautocomplete={values.idbeneficio}
                    filtro={"descripcion"}
                    promSelectList={getAllBeneficios()}
                    valorSalida="id"
                    onChange={handleChangeForm}
                    disabled={isUserCaja}
                />
            </Grid>
            <Grid xs={12} item>
                <Controls.Input
                    label="CBU"
                    name="cbu"
                    value={values.cbu}
                    onChange={handleChangeForm}
                    disabled={isUserCaja}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography type='h6'>
                    Fechas libre deuda :
                </Typography>
            </Grid>
            {/* *******************LIBRE DEUDA************************************************************ */}
            <Grid item xs={6}>
                <Controls.DateField
                    name="libredeuda"
                    value={values.libredeuda}
                    onChange={handleChangeForm}
                    title="Libre Deuda"
                />
            </Grid>
            <Grid item xs={6}>
                <Controls.DateField
                    name="libredeudacursar"
                    value={values.libredeudacursar}
                    onChange={handleChangeForm}
                    title="Libre Deuda Cursar"
                />
            </Grid>
            <Grid item xs={6}>
                <Controls.DateField
                    name="libredeudarendir"
                    value={values.libredeudarendir}
                    onChange={handleChangeForm}
                    title="Libre deuda rendir"
                />
            </Grid>
            <Grid item xs={6}>
                <Controls.DateField
                    name="libredeudarendirparcial"
                    value={values.libredeudarendirparcial}
                    onChange={handleChangeForm}
                    title="Libre deuda rendir PARCIAL"
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            {/* *************************Checkbox ********************************** */}
            {isUserCaja ? null :
                <>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Autogestion Activo"
                            name="autogestionactivo"
                            value={values.autogestionactivo}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Baja"
                            name="baja"
                            value={values.baja}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Activo"
                            name="activo"
                            value={values.activo}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Acredita Banco"
                            name="acreditabanco"
                            value={values.acreditabanco}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Recursante"
                            name="recursante"
                            value={values.recursante}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                    <Grid xs={6} container item>
                        <Controls.Checkbox
                            label="Fin"
                            name="fin"
                            value={values.fin}
                            onChange={handleChangeForm}
                            disabled={isUserCaja}
                        />
                    </Grid>
                </>

            }

            {/* ========================================================================================== */}


            {/* ====================================FECHAS FIN====================================================== */}
            {isUserCaja ? null : <> 
                <Grid item xs={12}>
                    <Typography type='h6'>
                        Fechas fin :
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Controls.DateField
                        name="findesde"
                        value={values.findesde}
                        onChange={handleChangeForm}
                        disabled={isUserCaja}
                        title="Fin Desde"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.DateField
                        name="finhasta"
                        value={values.finhasta}
                        onChange={handleChangeForm}
                        disabled={isUserCaja}
                        title="Fin Hasta"
                    />
                </Grid>
            </>
            }
        </FormContainer>
    )
}
export default FormEditCollapseTable