import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Grid, Typography, InputAdornment } from '@material-ui/core';
import { useForm, Controller, useWatch } from "react-hook-form";

// Import Componentes
import Controls from '../../componentes/Forms/Controls';
import FormContainer from '../../componentes/Forms/FormContainer';

// Custom hooks
import * as useFormCustom from '../../hooks/useForm'
import useEnviarDatos from '../../hooks/useEnviarDatos';

import { getAllModosPagos, getTiposComprobantes } from '../../Redux/actions/caja/CajasActionCreator';

//Api
import { CajaApi } from '../../api/CajaApi';

//utils 
import { decodeToken } from '../../utils/authToken';


const FormEditMovCaja = ({ recargarTabla = () => { }, detallesMov = () => { } }) => {

    const [isUserCaja, setIsUserCaja] = useState(false);

    //montoArancel
    const initialFValues = {
        fechamov: '',
        id: '',
        recibo: '',
        descripcion: '',
        anulado: '',
        monto: '',
        codigoAfip: '',
        idmodospago: '',
        "ARANCEL": 0,
        "BONIFIC": 0,
        "INTERESES": 0,
        "BIBLIOTEC": 0,
        "MORATORIA": 0,
    };

    const { detallesData } = useSelector((state) => state.GlobalState);

    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
        formEdit
    } = useFormCustom.useForm({
        ...initialFValues,
    })

    const {
        control,
        handleSubmit,
        resetField,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: { ...initialFValues, ...detallesData, },
    });

    const montoTotal = useWatch({
        control,
        name: 'monto'
    })
    const setDetallesMov = () => {
        detallesMov()
            .then(({ data }) => {
                setValue("ARANCEL", data.find((elem) => elem.descripcion == "ARANCEL").valor)
                setValue("BONIFIC", data.find((elem) => elem.descripcion == "BONIFIC").valor)
                setValue("INTERESES", data.find((elem) => elem.descripcion == "INTERESES").valor)
                setValue("BIBLIOTEC", data.find((elem) => elem.descripcion == "BIBLIOTEC").valor)
                setValue("MORATORIA", data.find((elem) => elem.descripcion == "MORATORIA").valor)

            })
            .catch(err => console.log("ESTE ES EL ERROR ", err))
    }

    const calMontoTotal = (bon = null) => {
        let montoTotal = (
            parseFloat(getValues("ARANCEL")) +
            parseFloat(getValues("BIBLIOTEC")) +
            parseInt(parseFloat(bon ? bon : getValues("BONIFIC"))) +
            parseFloat(getValues("INTERESES")))
        return setValue("monto", montoTotal)
    }

    const calcBonificacion = (event) => {

        const { value } = event.target
        let bon = 0
        if (value.includes('%') && (typeof value == 'string' && value.length > 1 ? true : false)) {
            bon = parseFloat(-1 * (
                parseFloat(getValues("BIBLIOTEC")) +
                parseFloat(getValues("ARANCEL"))) * (value.replaceAll('%', '') / 100))
            setValue("BONIFIC", parseFloat((getValues("BIBLIOTEC")) + parseFloat(getValues("ARANCEL"))) * (value.replaceAll('%', '') / 100))
            setValue("BONIFIC", getValues('BONIFIC') * (-1))

            calMontoTotal(bon)
            return
        }
        setValue("BONIFIC", typeof value == 'string' ? value : parseFloat(value))
        calMontoTotal()
    }

    const { sendData: editarMovCaja } = useEnviarDatos({
        action: new CajaApi().updateMovCaja,
        textSucces: 'Mov de caja Actualizado',
        recargarTabla,
        funcSuccess: () => { }
    })

    const onSubmit = (data) => {
        return editarMovCaja({
            ...values,
            ...data,
            tipoDocumento: values.codigoAfip,
            idmodospago: values.idmodospago
        })
    }


    console.log("que hay en los valores del formulario ", values)

    const getTipoDocumentos = async () => {
        return await [
            {
                descripcion: 'DNI',
                codigo: 96
            },
            {
                descripcion: 'CUIT',
                codigo: 80
            },
            {
                descripcion: 'CUIL',
                codigo: 86
            },
            {
                descripcion: 'CI Extranjera',
                codigo: 91
            }
        ]
    }

    useEffect(() => {
        setDetallesMov()
        const esCaja = decodeToken('idCaja');
        setIsUserCaja(esCaja);
    }, [])

    return (
        <FormContainer
            onSubmit={handleSubmit(onSubmit)}
            width="45vw"
            LabelButtonSubmit="Actualizar Mov"
            resetForm={resetForm}
            title="Editar Mov. de Caja">
            <Grid style={{ padding: '10px', height: '100% !important' }} container spacing={3}>
                {/*============================================== fila ============================================== */}
                <Grid item xs={6}>
                    <Controller
                        name="nombrePersona"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="Nombre"
                                name="nombrePersona"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="descripcionCuenta"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="Cuenta"
                                name="descripcionCuenta"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="monto"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="Monto"
                                name="monto"
                                disabled={isUserCaja}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    setValue("ARANCEL", e.target.value)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="descripcion"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="Concepto"
                                name="descripcion"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="fechamov"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.DateField
                                title="Fecha del Movimiento"
                                name="fechamov"
                                value={value}
                                onChange={isUserCaja ? ()=>{} :onChange}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controller
                        name="recibo"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="Recibo"
                                name="recibo"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.AutocompleteSelect
                        promSelectList={getAllModosPagos()}
                        name="idmodospago"
                        label="Forma de pago"
                        filtro='descripcion'
                        valorSalida="id"
                        valueautocomplete={values.idmodospago}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.AutocompleteSelect
                        promSelectList={getTipoDocumentos()}
                        name="codigoAfip"
                        label="Tipo de Documento"
                        filtro='descripcion'
                        valorSalida="codigo"
                        disabled={isUserCaja}
                        valueautocomplete={values.codigoAfip}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="anulado"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Checkbox
                                label="Anulado"
                                name="anulado"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />

                </Grid>

                {/* ==================================== DETALLES DEL MOVIMIENTO ==================================== */}
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Detalles del Movimiento
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name="ARANCEL"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="ARANCEL"
                                name="ARANCEL"
                                value={value}
                                disabled={isUserCaja}
                                onChange={(e) => {
                                    onChange(e)
                                    calMontoTotal()
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="BONIFIC"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="BONIFIC."
                                name="BONIFIC"
                                value={value}
                                disabled={isUserCaja}
                                onChange={(e) => {
                                    onChange(e)
                                    calcBonificacion(e)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="INTERESES"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="INTERESES"
                                name="INTERESES"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="BIBLIOTEC"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="BIBLIOTEC"
                                name="BIBLIOTEC"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="MORATORIA"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Controls.Input
                                label="MORATORIA"
                                name="MORATORIA"
                                value={value}
                                disabled={isUserCaja}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </FormContainer >
    )
}
export default FormEditMovCaja